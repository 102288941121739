.ticket-listing {
  .ticket-header-image {
    background-image: url('./assets/images/ticket-header-image.jpg');
    background-position: center;
    background-size: cover;
    margin: 0;
    padding: 0;
    height: calc(100vh - 72px);
    @include media-breakpoint-up(md) {
      height: calc(100vh - 60px);
    }
  }

  .list-page {
    padding-top: 8vh;

    // padding-left: 15vw;
    // padding-right: 15vw;
    // @include media-breakpoint-down(md) {
    //   padding-left: 8vw;
    //   padding-right: 8vw;
    // }
  }
}
